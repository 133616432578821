.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background */
    backdrop-filter: blur(0.1px); /* Apply blur effect */
    z-index: 9998; /* Ensure it's below the menu but above other content */
    transition: all 0.3s ease;
}


.mobileMenu {
    position: fixed;
    left: 100%;
    top: 0;
    z-index: 9999;
    height: 400px;
    width: 50%;
    background: #848dc7;
    transition: all 0.3s ease 0s;
    box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
    overflow: hidden;
    border-bottom-left-radius: 40px;
    /* Centering content */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.mobileMenu .footer {
    position: absolute;
    bottom: -70px;
    width: 100%;
  }
  
.responsivemenu .card-body{
    background: #848dc7;
}
.mobileMenu h2{
    padding-left: 0;
}
.mobileMenu.show{
    left: 50%;
}
.showmenu {
    position: relative;
    top: 0;
    z-index: 999;
    cursor: pointer;
}
.responsivemenu {
    list-style: none;
    padding-left: 0;
    padding-top: 10px;
}

/* .responsivemenu li p {
    display: block;
    padding: 15px 20px;
    font-size: 18px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    border-radius: 8px;
    margin: 8px 0;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */
/* .responsivemenu li a,
.responsivemenu li p{
    display: block;
    padding: 13px 35px;
    font-size: 20px;
    letter-spacing: 0;
    text-transform: capitalize;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
} */


/* .responsivemenu li p{
    position: relative;
    margin-bottom: 0;
}
.responsivemenu ul{
    list-style: none;
}

.responsivemenu .card{
  border:none
}
.responsivemenu .card-body{
  padding-top: 0;
}

.responsivemenu .card-body li {
    background: #848dc7;
}

.responsivemenu li a{
    position: relative;
}
.responsivemenu li i{
    position: absolute;
    right: 20px;
    top: 17px;
} */
@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&family=Jost&family=Poppins:wght@300;400;500;600;700;800&display=swap');

.responsivemenu li a,
.responsivemenu li p {
    align-items: center;
    display: flex;
    padding: 10px 20px;
    font-size: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    border-radius: 120px;
    margin: 8px 0;
    background-color: rgba(255, 255, 255, 0.01);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    text-decoration-color: #ffffff;
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-thickness: auto;
    text-size-adjust: 100%;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
}

/* ela */
/* .responsivemenu li a,
.responsivemenu li p {
align-items: center;
color: rgba(61, 57, 53);
cursor: pointer;
display: flex;
font-family: Poppins, sans-serif;
font-size: 20px;
font-weight: 400;
height: 30px;
justify-content: space-between;
list-style-image: none;
list-style-position: outside;
list-style-type: none;
padding-bottom: 10px;
padding-left: 0px;
padding-right: 0px;
padding-top: 10px;
text-align: left;
text-decoration-color: rgb(61, 57, 53);
text-decoration-line: none;
text-decoration-style: solid;
text-decoration-thickness: auto;
text-size-adjust: 100%;
text-transform: uppercase;
} */
.responsivemenu li p:hover {
    background-color: #ffffff;
    color: #6a4e52;
    transform: translateY(-3px);
}

.responsivemenu li p i {
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.responsivemenu li p:hover i {
    transform: translateX(5px);
}

.responsivemenu ul {
    list-style: none;
    padding-left: 0;
}

.responsivemenu .card {
    border: none;
}

.responsivemenu .card-body {
    padding-top: 0;
}
.showmenu i {
    font-size: 30px;
    color: #fff;
}

.mobileMenu{
    display: none;
}
.showmenu{
    display: none;
}
@media (max-width:992px){
    .showmenu {
        display: block;
        width: 40px;
        height: 36px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        padding-right: 60px;
    }

    .showmenu button:focus{
        outline: none;
        box-shadow: none;
    }
    .showmenu button span{
        background-color: #fff;
        width: 20px;
        display: block;
        height: 2px;
        margin: 0 auto;
        margin-bottom: 5px;
    }
    .mobileMenu{
        display: block;
    }
}



.middle-header-3 .showmenu {
    top: 36px;
}
.middle-header2 .showmenu {
    top: 26px;
}

.middle-header-3 .showmenu i{
    color: #fff;
}


/* @media(max-width:450px){
    .mobileMenu {
        left: 240px;
        width: 240px;
    }
    .responsivemenu li a, .responsivemenu li p {
        font-size: 18px;
        padding: 13px 25px;
    }
} */


.responsivemenu a:not([href]):not([class]), 
.responsivemenu a:not([href]):not([class]):hover{
 color: #ddd;
}