/*==========================================
7. wpo-about-section
===========================================*/

/* 7.1 wpo-team-section */

.wpo-team-section{
    padding-bottom: 90px;
    padding-top: 0;

    @media(max-width:991px){
      padding-bottom: 70px;
    }
    @media(max-width:575px){
      padding-bottom: 50px;
    }
    .wpo-team-wrap{
        .wpo-team-item{
            text-align: center;
            margin-bottom: 30px;
            padding: 20px;
	        box-shadow: 0px 0px 6.1px 3.9px rgba(28, 31, 86, 0.04);

            .wpo-team-img{
                margin: 0 auto;
                position: relative;
                overflow: hidden;

                img{
                    transform: scale(1);
                    transition: all .3s;

                }
            }

            &:hover{
                .wpo-team-img{
                    img{
                        transform: scale(1.2);
                    }
                }
            }

            .wpo-team-text{
                padding-top: 20px;
                h3{
                    font-size: 22px;
                    font-weight: 600;

                    a{
                        color: $dark-gray;

                        &:hover{
                            color: $theme-primary-color-s2;
                        }
                    }
                }

                span{
                    font-size: 16px;
                    color: $theme-primary-color;
                }

                ul{
                    list-style: none;
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;

                    li{
                        a{
                            color: #002642;
                            width: 42px;
                            height: 42px;
                            line-height: 42px;
                            background: $section-bg-color;
                            display: block;
                            text-align: center;
                            border-radius: 50%;
                            font-size: 18px;

                            i{
                                font-size: 15px;
                            }

                            &:hover{
                                background: $theme-primary-color-s2;
                                color: $white;
                            }
                        }
                    }

                    li + li {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

/*==========================================
7. wpo-about-section
===========================================*/

/* 7.1 social-section */

.social-section{
    padding-bottom: 90px;
    padding-top: 0;

    @media(max-width:991px){
      padding-bottom: 70px;
    }
    @media(max-width:575px){
      padding-bottom: 50px;
    }
    .social-wrap{
        .social-item{
            text-align: center;
            margin-bottom: 30px;
            // padding: 0.1px;
	        box-shadow: -70px 0px 6.1px 7px rgba(28, 31, 86, 0.06);
            
            padding: 3px;
            // border-radius: 16px;
            transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
            position: relative;
            // overflow: hidden;

            &:hover {
                transform: translateY(-1px);
                box-shadow: 0px 20px 40px rgba(28, 31, 86, 0.15);
            }
            .social-img{
                margin: 0 auto;
                position: relative;
                overflow: hidden;

                img{
                    transform: scale(1);
                    transition: all .3s;

                }
            }

            &:hover{
                .social-img{
                    img{
                        transform: scale(1.2);
                    }
                }
            }

            .social-text{
                // padding-top: 20px;
                h3{
                    font-size: 22px;
                    font-weight: 600;

                    a{
                        color: $dark-gray;

                        &:hover{
                            color: $theme-primary-color-s2;
                        }
                    }
                }

                span{
                    font-size: 16px;
                    color: $theme-primary-color;
                }

                ul{
                    list-style: none;
                    display: flex;
                    justify-content: center;
                    // margin-top: 20px;

                    li{
                        a{
                            color: #002642;
                            width: 42px;
                            height: 42px;
                            line-height: 42px;
                            background: $section-bg-color;
                            display: block;
                            text-align: center;
                            border-radius: 50%;
                            font-size: 18px;
                            transition: all 0.4s ease-in-out;
                            box-shadow: 0px 7px 15px rgba(11, 2, 27, 0.3);
                            position: relative;
                            overflow: hidden;

                            a::before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background: rgba(255, 255, 255, 0.2);
                            top: 0;
                            left: 0;
                            border-radius: 50%;
                            transform: scale(0);
                            transition: transform 0.3s ease-in-out;
                        }

                            i{
                                font-size: 15px;
                            }

                            &:hover{
                                background: linear-gradient(135deg, #ffffff, #848dc7);
                                transform: scale(1.2);
                                // color: $white;
                            }
                        }
                    }

                    li + li {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}



/*==========================================
7. wpo-about-section
===========================================*/

/* 7.1 social footer */

.social-section-footer{
    padding-bottom: 90px;
    padding-top: 0;

    @media(max-width:991px){
      padding-bottom: 70px;
    }
    @media(max-width:575px){
      padding-bottom: 50px;
    }
    .social-wrap-footer{
        .social-item-footer{
            text-align: center;
            margin-bottom: 30px;
            // padding: 0.1px;
	        // box-shadow: -70px 0px 6.1px 7px rgba(28, 31, 86, 0.06);
            
            padding: 3px;
            // border-radius: 16px;
            transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
            position: relative;
            // overflow: hidden;

            &:hover {
                transform: translateY(-1px);
                // box-shadow: 0px 20px 40px rgba(28, 31, 86, 0.15);
            }
            .social-img-footer{
                margin: 0 auto;
                position: relative;
                overflow: hidden;

                img{
                    transform: scale(1);
                    transition: all .3s;

                }
            }

            &:hover{
                .social-img{
                    img{
                        transform: scale(1.2);
                    }
                }
            }

            .social-text-footer{
                // padding-top: 20px;
                h3{
                    font-size: 22px;
                    font-weight: 600;

                    a{
                        color: $dark-gray;

                        &:hover{
                            color: $theme-primary-color-s2;
                        }
                    }
                }

                span{
                    font-size: 16px;
                    color: $theme-primary-color;
                }

                ul{
                    list-style: none;
                    display: flex;
                    justify-content: center;
                    // margin-top: 20px;

                    li{
                        a{
                            color: #002642;
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            background: #fff;
                            display: block;
                            text-align: center;
                            border-radius: 50%;
                            font-size: 18px;
                            transition: all 0.4s ease-in-out;
                            box-shadow: 0px 7px 15px rgba(11, 2, 27, 0.3);
                            position: relative;
                            overflow: hidden;
                            border: 1px solid #dddddd;

                            a::before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background: rgba(255, 255, 255, 0.2);
                            top: 0;
                            left: 0;
                            border-radius: 50%;
                            transform: scale(0);
                            transition: transform 0.3s ease-in-out;
                        }

                            i{
                                font-size: 15px;
                            }

                            &:hover{
                                background: linear-gradient(135deg, #ffffff, #848dc7);
                                transform: scale(1.2);
                            }
                        }
                    }

                    li + li {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
