.wpo-section-title,
.wpo-section-title-s2 {
	margin-bottom: 60px;
	text-align: center;
	
	@include media-query(767px) {
		margin-bottom: 40px;
	}

	span{
		text-transform: capitalize;
		font-size: 20px;
		color: $theme-primary-color-s2;
		font-family: $base-font;

		@include media-query(575px) {
			font-size: 16px;
		}
	}

	h2 {
		font-size: 45px;
		line-height: 55px;
		margin: 0;
		position: relative;
		font-family: $heading-font;
		font-weight: 500;
		color: $theme-primary-color;
		display: inline-block;
		padding-bottom: 20px;
		position: relative;
		margin-top: 10px;

		&::after{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 120%;
			height: 1px;
			content: "";
			background: #e7e7e7;
			left: -10%;
		}
		&::before{
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -10px;
			width: 70%;
			height: 1px;
			content: "";
			background: #e7e7e7;
		}


		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(575px) {
			font-size: 22px;
		}

	}

	p{
		font-size: 18px;
	}

	.section-title-img{
        max-width: 400px;
		margin: 0 auto;
		position: relative;
		margin-bottom: -35px;
    	left: -6px;
	}

}

.wpo-team-wrap .wpo-section-title .section-title-img .wpo-team-item img {
	width: 200px; /* Ensure the width is equal to height */
	height: 200px;
	border-radius: 50%; /* Makes it a circle */
	object-fit: cover; /* Prevents distortion */
  }

.wpo-team-wrap .wpo-section-title .section-title-img .wpo-team-item span {
	font-size: 24px !important;
	font-weight: bold;
	color: #222;
	position: relative;
	display: inline-block;  
  }

.wpo-team-wrap .wpo-section-title .section-title-img .wpo-team-item span::after  {
	content: "";
	position: absolute;
	width: 100%;
	height: 3px;
	background-color: #a3888c;
	left: 0;
	bottom: -5px;
	transform: scaleX(0);
	transition: transform 0.3s ease-in-out;  
  }
.wpo-team-wrap .wpo-section-title .section-title-img .wpo-team-item span:hover::after {
	transform: scaleX(1);
  }

.wpo-section-title-s2 {
	.section-title-img{
		.round-ball{
			position: absolute;
			left: 50%;
			width: 15px;
			height: 15px;
			border: 1px solid  $theme-primary-color;
			border-radius: 50%;
			transform: translateX(-50%);
			top: -5px;
		}

		&:after,&:before{
			width: 144px;

			@include media-query(575px) {
				width: 80px;
			}
		}
	}
}